<template>
    <div>
        <div class="iconSelectContainer">
            <div class="iconSelectField" @click="openOptions">
                <div class="selectedIcon" v-if="localValue">
                    <i :class="`fas fa-${localValue}`"></i>
                </div>
                <v-text-field
                    v-model="search"
                    @keyup="openOptions"
                    label="Icoon zoeken (Engels)"
                    :append-icon="(showIcons) ? 'mdi-close' : null"
                    @click:append="clearSearch"
                ></v-text-field>
            </div>
            <div class="iconSelectNavigation" v-if="showIcons" v-click-outside="e => closeOptions(e)">
                <div class="previous navButton" @click="previousGroup">
                    <i class="fas fa-chevron-left"></i>
                </div>

                <div class="iconSelectOptions">
                    <div v-for="(row, index) of activeGroup" :key="index" class="iconRow">
                        <div v-for="icon of row" :key="icon.key" class="icon" :class="!icon.key ? 'iconEmpty' : ''" @click="selectIcon(icon)">
                            <i :class="`fas fa-fw fa-${icon.key}`"></i>
                        </div>
                    </div>
                </div>

                <div class="next navButton" @click="nextGroup">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import iconsJson from '../assets/fontawesome/icons.json';

export default {
    name: 'IconSelect',
    props: {
        value: {
            type: String,
            default: null
        }
    },
    data(){
        return {
            localValue: null,
            search: null,
            options: [],
            optionGroups: [],
            showIcons: false,
            activeGroupIndex: 0,
            itemsPerRow: null,
        }
    },
    watch: {
        value(newVal){
            this.localValue = newVal;
        },
        search(){
            this.activeGroupIndex = 0;
            this.createGroups(this.search);
        }
    },
    computed: {
        activeGroup(){
            return this.optionGroups[this.activeGroupIndex];
        }
    },
    created(){

        this.options = Object.entries(iconsJson)
            // eslint-disable-next-line no-unused-vars
            .filter(([_, value]) => value.styles.includes('light'))
            .map(([key, value]) => ({key: key, label: value.label, terms: value.search.terms, search: `${value.label} ${value.search.terms.join(' ')}`}));

        window.addEventListener('resize', this.handleResize);
        this.handleResize();

    },
    beforeDestroy(){
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {

        handleResize(){
            const newItemsPerRow = (window.innerWidth > 600)
                ? 10
                : 6;

            if (this.itemsPerRow != newItemsPerRow){
                if (this.itemsPerRow < newItemsPerRow) this.activeGroupIndex = 0;
                this.itemsPerRow = newItemsPerRow;
                this.createGroups();
            }
        },

        createGroups(searchQuery = null){
            const options = searchQuery 
                ? this.options.filter(option => option.search.includes(searchQuery)) 
                : this.options;

            const groups = [];
            const groupSize = 4 * this.itemsPerRow;

            for (let i = 0; i < options.length; i += groupSize) {
                const group = [];
                for (let j = 0; j < 4; j++) {
                    const start = i + j * this.itemsPerRow;
                    const end = start + this.itemsPerRow;
                    let subGroup = options.slice(start, end);

                    while (subGroup.length < this.itemsPerRow) {
                        subGroup.push({});
                    }

                    group.push(subGroup);
                }
                groups.push(group);
            }

            this.optionGroups = groups;
        },

        openOptions(){
            console.log('openOptions');
            this.showIcons = true;
        },

        closeOptions(e){
            console.log('closeOptions');
            if (e.target.closest('.iconSelectContainer')) return;


            this.showIcons = false;
        },

        selectIcon(icon){
            console.log('selectIcon');
            this.localValue = icon.key;
            this.search = null;
            this.showIcons = false;

            this.$emit('input', this.localValue);
        },

        previousGroup(){
            console.log('previousGroup');
            if (this.activeGroupIndex > 0) this.activeGroupIndex--; 
        },

        nextGroup(){
            console.log('nextGroup');
            if (this.activeGroupIndex < this.optionGroups.length - 1) this.activeGroupIndex++; 
        },

        clearSearch(){
            console.log('clearSearch');
            this.search = null;
            this.showIcons = false;
        },

    }
}
</script>

<style scoped lang="scss">

.iconSelectContainer{

    .iconSelectField{
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
    }

    .iconSelectNavigation{
        display: flex;
        position: absolute;
        z-index: 5;

        .iconSelectOptions{
            gap: 10px;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            box-sizing: border-box;
            background: #e9e9e9;
            border-block: 1px solid #838383;
    
            .iconRow{
                display: flex;
                gap: 10px;
                padding: 0 10px;
    
                .icon{
                    padding: 5px;
                    background: #fff;
                    cursor: pointer;

                    &.iconEmpty{
                        background: transparent;
                    }
                }
            }
        }

        .navButton{
            padding: 10px;
            background: #d2d2d2;
            display: flex;
            align-items: center;
            border: 1px solid #838383;
            cursor: pointer;
        }
    }


}

</style>
