<template>
    <div v-if="componentReady">
        <div class="topBar" :class="selectedAlbum ? 'column' : ''">
            <div class="title">
                <span :class="selectedAlbum ? 'clickable' : ''" @click="back">{{$t('photoalbum.title')}}</span>
                <div v-if="selectedAlbum" class="breadcrumb">
                    <span class="chevron">></span>
                    <span>
                        <v-text-field
                            variant="plain"
                            v-model="albums.find(a => a.id == selectedAlbum).name"
                            @change="changePhotoAlbumName"
                            :rules="rules.photoAlbumName">
                        </v-text-field>
                    </span>
                    <span class="icon">
                        <v-autocomplete :label="$t('photoalbum.icon')" v-model="albums.find(a => a.id == selectedAlbum).icon" :items="icons" @change="changePhotoAlbumIcon" item-text="search" item-value="key" clearable class="iconSelect">

                            <!-- Slot for items -->
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <span class="customRow"><div class="icon"><i :class="`fa-fw fa-light fa-${item.key}`"></i></div></span>
                                </v-list-item-content>
                            </template>

                            <!-- Additional slot for custom content when using chips -->
                            <template v-slot:selection="{ item }">
                                <span class="customRow selection"><div class="icon"><i :class="`fa-fw fa-light fa-${item.key}`"></i></div></span>
                            </template>

                        </v-autocomplete>
                    </span>
                </div>
            </div>
            <div class="buttons">

                <!-- temp -->
                <!-- <v-btn color="blue-grey lighten-5" @click="test">
                    test
                </v-btn>  -->

                <v-tooltip v-if="!selectedAlbum" bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn color="primary" v-on="on" @click="openAddPhotoAlbumModal">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    </template>
                    <span>{{$t('photoalbum.buttons.add')}}</span> 
                </v-tooltip>
                
                <v-tooltip v-if="selectedAlbum" bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn color="blue-grey lighten-5" v-on="on" @click="back">
                        <v-icon>mdi-keyboard-backspace</v-icon>
                    </v-btn>
                    </template>
                    <span>{{$t('photoalbum.buttons.back')}}</span> 
                </v-tooltip>

                <v-tooltip v-if="selectedAlbum" bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn color="danger" v-on="on" @click="openDeletePhotoAlbumModal">
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                    </template>
                    <span>{{$t('photoalbum.buttons.delete')}}</span> 
                </v-tooltip>

            </div>
        </div>

        <div class="albums" v-if="!selectedAlbum">
            <div class="album" v-for="album of albumsOnPage" :key="album.id" @click="selectPhotoAlbum(album.id)">
                <div class="img">
                    <img v-if="album.photos.length > 0" :src="album.photos[0].url">
                </div>
                <div class="inner">
                    <div class="title">{{album.name}}</div>
                    <div class="amount">{{album.photos.length}} {{ $t(`photoalbum.photos`) }}</div>
                </div>
            </div>
            <div v-if="albumsOnPage.length == 0">
                {{ $t(`photoalbum.error.noAlbumsFound`) }}
            </div>
        </div>

        <div class="selectedAlbum" v-if="selectedAlbum">
            <div class="photos">
                <VueFileAgent 
                    v-model="albums.find(a => a.id == selectedAlbum).photos"
                    :multiple="true"
                    :key="selectedAlbum"
                    :deletable="true"
                    :editable="true"
                    :meta="true"
                    :accept="'image/png, image/jpeg, image/jpg'"
                    :maxSize="'2GB'"
                    :helpText="$t('database.click_here_to_upload')"
                    :errorText="{
                        type: 'Invalid file type. Only images are allowed',
                    }"
                    ref="VueFileUpload"
                    @beforedelete="onBeforeDelete($event)"
                    @rename="changePhotoName($event)"
                    @select="onSelect($event)"
                ></VueFileAgent>
            </div>
        </div>

        <v-pagination v-if="!selectedAlbum && Math.ceil(albums.length / albumsPerPage) > 1" :length="Math.ceil(albums.length / albumsPerPage)" v-model="currentPage" @input="getAlbumsForPage"></v-pagination>

        <v-dialog v-model="modal.add.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`photoalbum.modal.add.title`) }}
                </v-card-title>

                <div class="modalBody">
                    <v-text-field 
                        v-model="modal.add.name" 
                        :label="$t('photoalbum.modal.add.name')"
                        :rules="rules.photoAlbumName"
                        ref="addPhotoAlbumName">
                    </v-text-field>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.add.display = false">
                        {{ $t("photoalbum.modal.add.cancel") }}
                    </v-btn>
                    <v-btn @click="addPhotoAlbum" color="primary">
                        {{ $t("photoalbum.modal.add.submit") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="modal.delete.display" width="500">
            <v-card>
                <v-card-title>
                    {{ $t(`photoalbum.modal.delete.title`) }}
                </v-card-title>

                <v-card-text>
                    {{ $t("photoalbum.modal.delete.message.start") }} "{{ modal.delete.name }}" {{ $t("photoalbum.modal.delete.message.end") }}
                </v-card-text>
                
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.delete.display = false">
                        {{ $t("photoalbum.modal.delete.cancel") }}
                    </v-btn>
                    <v-btn @click="deletePhotoAlbum" color="danger">
                        {{ $t("photoalbum.modal.delete.submit") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="error.photoName.active.status" :timeout="3000" color="red">
            <strong>{{ error.photoName.active.message }}</strong>
        </v-snackbar>
        
        <v-snackbar v-model="error.spaceLimit.active" :timeout="5000" color="red">
            <strong>{{ error.spaceLimit.message }}</strong>
        </v-snackbar>

        <v-snackbar v-model="error.photoAlbumEmpty.active" :timeout="3000" color="red">
            <strong>{{ error.photoAlbumEmpty.message }}</strong>
        </v-snackbar>
    </div>
</template>

<script>
import axios from "axios";
import { PHOTO_ALBUM_URL } from "../config";
import iconsJson from '../assets/fontawesome/icons.json';
import { ILLI_API_SERVER_URL } from "../config";
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

export default {
    props: {
        streamIds: {
            type: Array,
            default: () => [],
        }
    },
    created(){
        this.getPhotoAlbums().then(() => {

            this.getPhotoAlbumsUsedSpace()

            window.addEventListener('resize', this.handleResize);
            this.handleResize();

            this.componentReady = true;

            //  Set icons
            this.icons = Object.entries(iconsJson)
                // eslint-disable-next-line no-unused-vars
                .filter(([_, value]) => value.styles.includes('light'))
                .map(([key, value]) => ({key: key, label: value.label, terms: value.search.terms, search: `${value.label} ${value.search.terms.join(' ')}`}));
        

            // Set error messages
            this.error.photoAlbumName.messages.required = this.$t('photoalbum.error.photoAlbumName.required');
            this.error.photoAlbumName.messages.toLong = this.$t('photoalbum.error.photoAlbumName.toLong');
            this.error.photoAlbumName.messages.alreadyExists = this.$t('photoalbum.error.photoAlbumName.alreadyExists');
            this.error.photoName.messages.required = this.$t('photoalbum.error.photoName.required');
            this.error.photoName.messages.toLong = this.$t('photoalbum.error.photoName.toLong');
            this.error.spaceLimit.message = this.$t('photoalbum.error.spaceLimit').replace('_max_allowed_', this.maxAllowedSpaceString);
            this.error.photoAlbumEmpty.message = this.$t('photoalbum.error.photoAlbumEmpty');
        });
    },
    data() {
        return {
            componentReady: false,
            FileBrowserVueFileAgentStyles: VueFileAgentStyles,
    
            originalAlbums: [],
            albums: [],
            
            icons: [],
    
            albumsPerPage: 10,
            albumsOnPage: [],
            currentPage: 1,
    
            selectedAlbum: null,
            
            usedSpace: 0,
            maxAllowedSpace: 2147483648,
            maxAllowedSpaceString: '2GB',
    
            rules: {
                photoAlbumName: [
                    val => (val?.length > 0) || this.error.photoAlbumName.messages.required,
                    val => (val?.length < 20) || this.error.photoAlbumName.messages.toLong,
                    val => !this.photoAlbumNameAlreadyExists(val, this.selectedAlbum) || this.error.photoAlbumName.messages.alreadyExists
                ],
                photoName: [
                    val => (val?.length > 0) || this.error.photoName.messages.required,
                    val => (val?.length < 20) || this.error.photoName.messages.toLong,
                ]
            },
            validNames: {
                albums: [],
                photos: [],
            },
            error: {
                photoAlbumName: {
                    messages: {
                        required: '',
                        toLong: '',
                        alreadyExists: '',
                    }
                },
                photoName: {
                    messages: {
                        required: '',
                        toLong: '',
                    },
                    active: {
                        status: false,
                        message: null,
                    }
                },
                spaceLimit: {
                    active: false,
                    message: ''
                },
                photoAlbumEmpty: {
                    active: false,
                    message: ''
                }
            },
    
            toDelete: {
                albums: [],
                photos: [],
            },
            toEdit: {
                albums: [],
                photos: [],
            },
    
            modal: {
                add: {
                    display: false,
                    name: null,
                    error: false,
                },
                delete: {
                    display: false,
                    name: null,
                }
            },

            tempIdPhoto: 0
        }
    },
    methods: {
        handleResize() {
            const width = window.innerWidth;

            if (width > 1000){
                this.albumsPerPage = 10;
            }
            else if (width <= 600){
                this.albumsPerPage = 4;
            }
            else if (width <= 800){
                this.albumsPerPage = 6;
            }
            else { // > 1000
                this.albumsPerPage = 8;
            }

            this.currentPage = 1;
            this.getAlbumsForPage();
        },

        getPhotoAlbumsUsedSpace(){
            const streamIds = this.streamIds.join('-');
            axios
                .get(`${ILLI_API_SERVER_URL}/stream/${streamIds}/photoAlbums/usedSpace`)
                .then((result) => {
                    // store used space of stream with most usage
                    this.usedSpace = Object.values(result.data).reduce((all,crr) => (Math.max(all, crr)), 0);
                    console.warn('used space', this.usedSpace);
                }).catch((err) => {
                    console.error({err});
                })
        },

        getPhotoAlbums(){
            return new Promise((resolve, reject) => {

                const streamIds = this.streamIds.join('-');

                axios
                .get(`${ILLI_API_SERVER_URL}/stream/${streamIds}/photoAlbums/`)
                .then((result) => {
                    console.warn({result});

                    this.albums = result.data.map(e => {
                        return {
                            new: false, 
                            id: e.id, 
                            name: e.name, 
                            icon: e.icon,
                            photos: e.photos.map(p => ({...p, name: `${p.name}.${p.extension}`, size: p.size, url: `${PHOTO_ALBUM_URL}${e.id}/${p.id}.${p.extension}`, ext: p.extension, type: `image/${p.extension}`}))
                        }
                    });

                    this.sortPhotoAlbums();

                    this.originalAlbums = [...this.albums];

                    this.validNames.albums = this.albums.map(a => ({id: a.id, name: a.name}));
                    this.validNames.photos = this.albums.flatMap(a => a.photos).map(p => ({id: p.id, name: p.name})); 

                    console.warn(
                        JSON.parse(JSON.stringify(this.albums))
                    );
                    
                    resolve();
                })
                .catch((err) => {
                    console.error({err});
                    reject();
                })
            });
        },

        getTempIdAlbum(){
            const maxId = Object.values(this.albums).reduce((all, crr) => (crr.id > all ? crr.id : all), 0);
            return maxId + 1;
        },

        getTempIdPhoto(){
            return this.tempIdPhoto++;
        },

        getAlbumsForPage(){
            const endIndex = this.albumsPerPage * this.currentPage;
            const startIndex = endIndex - this.albumsPerPage;
            this.albumsOnPage = this.albums.slice(startIndex, endIndex);
        },

        sortPhotoAlbums(){
            this.albums.sort((a,b) => a.name.localeCompare(b.name));
        },

        photoAlbumNameAlreadyExists(name, excludeAlbumId = null){
            return this.albums
                .filter(a => a.id != excludeAlbumId)
                .some(a => a.name == name);
        },

        splitNameAndExtension(fileNameAndExtension){
            const nameArr = fileNameAndExtension.split('.');
            const extension = nameArr.pop();
            const name = nameArr.join('.');
            return {name, extension};
        },

        // Vue File Agent

        onBeforeDelete: function (fileRecord) {

            this.usedSpace -= fileRecord.size;

            // Remove from albums
            const currentAlbum = this.albums.find(a => a.id == this.selectedAlbum).photos;
            const index = currentAlbum.indexOf(fileRecord); 
            currentAlbum.splice(index, 1);

            if (fileRecord.id){
                // Already on server | mark as ready for deletion
                this.deletePhoto(fileRecord.id);
            }
        },

        changePhotoName(photo){
            const newPhoto = (photo.lastModified !== undefined);
            const {name, extension} = this.splitNameAndExtension(photo.name());

            const errorMessage = this.rules.photoName
                .map(rule => rule(name))
                .find(e => e != true);

            if (!errorMessage){

                if (newPhoto){
                    // Change name
                    const currentFile = this.albums.find(a => a.id == this.selectedAlbum).photos.find(p => p.id == photo.id).file;
                    const newFile = new File([currentFile], `${name}.${extension}`, { type: currentFile.type });
                    this.albums.find(a => a.id == this.selectedAlbum).photos.find(p => p.id == photo.id).file = newFile;
                } else{
                    // Prepare name change for api
                    this.toEdit.photos.push({id: photo.id, name});
                } 
                
                // Set as valid name
                this.validNames.photos.find(e => e.id == photo.id).name = `${name}.${extension}`;

                this.emitChange();

            } else {

                // Reset to valid name
                const oldName = this.validNames.photos.find(e => e.id == photo.id).name;
                this.validNames.photos.find(e => e.id == photo.id).name = oldName;

                this.albums = this.albums.map(a => {
                    return {
                        ...a,
                        photos: a.photos.map(p => {
                            const name = (p.id == photo.id) ? oldName : p.name;
                            return {
                                ...p,
                                name
                            }
                        })
                    }
                });

                // Show error
                this.emitError(errorMessage);
            }
        },

        onSelect(photos){ // On upload

            // get new added size
            const spaceAdded = photos.reduce((all, crr) => (all + crr.size), 0);
            const spaceWithinLimits = (spaceAdded + this.usedSpace <= this.maxAllowedSpace);
            
            if (spaceWithinLimits){
                this.usedSpace += spaceAdded;

                // Add temp id to photos and validate photo names
                this.albums = this.albums.map(a => {
                    return {
                        ...a,
                        photos: a.photos.map(p => {
    
                            const id = p.id ?? this.getTempIdPhoto();
                            let nameAndExtension = p.file?.name ?? p.name;
                            let file = p.file;
    
                            // If new, check if name is valid
                            if (!p.id){ 
                                let {name, extension} = this.splitNameAndExtension(nameAndExtension);
                                
                                const errorMessage = this.rules.photoName
                                    .map(rule => rule(name))
                                    .find(e => e != true);
    
                                if (errorMessage){
                                    this.emitError(errorMessage);
                                }
    
                                nameAndExtension = (!errorMessage) ? `${name}.${extension}` : `foto.${extension}`;
    
                                this.validNames.photos.push({id, nameAndExtension});
    
                                file = new File([file], nameAndExtension, { type: file.type });
                            }
    
                            return {...p, id, file}
                        })
                    }
                });

                this.emitChange();

            } else {

                // remove files
                this.albums = this.albums.map(a => {
                    return {
                        ...a,
                        photos: a.photos.filter(p => p.id)
                    }
                });

                // show error
                this.emitError(this.error.spaceLimit.message, 5000);

            }
        },

        // Open modals

        openAddPhotoAlbumModal(){
            this.modal.add.error = false;
            this.modal.add.name = null;
            this.modal.add.display = true;
        },

        
        openDeletePhotoAlbumModal(){
            this.modal.delete.name = this.albums.find(a => a.id == this.selectedAlbum).name;
            this.modal.delete.display = true
        },

        // Manage albums & photos

        selectPhotoAlbum(id){
            this.selectedAlbum = id;
            console.log({id});
        },

        back(){
            // Set url in case new image got uploaded
            this.albums = this.albums.map(a => {
                return {
                    ...a, 
                    photos: a.photos.map(p => {
                        const url = (typeof p.url !== 'function') ? p.url : p.urlResized;
                        return {
                            ...p,
                            url
                        }
                    })
                }
            })

            this.getAlbumsForPage();
            this.selectedAlbum = null;
        },
        
        addPhotoAlbum(){
            const name = this.modal.add.name;

            const errorMessage = this.rules.photoAlbumName
                .map(rule => rule(name))
                .find(e => e != true);

            if (!errorMessage){

                const tempId = this.getTempIdAlbum();
    
                this.albums.push({new: true, id: tempId, name: name, icon: 'images', photos: []});
                this.validNames.albums.push({id: tempId, name: name});
                this.modal.add.display = false;
    
                this.sortPhotoAlbums();
                this.getAlbumsForPage();

                this.$refs.addPhotoAlbumName.reset(); // reset validation
                this.emitChange();

            }
        },

        deletePhotoAlbum(){
            console.log('DELETE ALBUM', this.selectedAlbum);

            const selectedAlbum = {...this.albums.find(a => a.id == this.selectedAlbum)};
            const selectedAlbumPhotoIds = selectedAlbum.photos.map(p => p.id);

            // Delete album 
            this.toDelete.albums.push(this.selectedAlbum);
            this.albums = this.albums.filter(a => a.id != this.selectedAlbum);
            this.selectedAlbum = null;
            this.getAlbumsForPage();

            // Remove albums and photos from toDelete and toEdit
            this.toDelete.photos = this.toDelete.photos.filter((id) => !selectedAlbumPhotoIds.includes(id));
            this.toEdit.albums = this.toEdit.albums.filter(a => a.id != selectedAlbum.id);
            this.toEdit.photos = this.toEdit.photos.filter(p => !selectedAlbumPhotoIds.includes(p.id));

            // Hide modal
            this.modal.delete.display = false;

            this.emitChange();
        },

        deletePhoto(photoId){

            // Delete photo
            console.log('DELETE PHOTO', photoId);
            this.toDelete.photos.push(photoId);

            // Remove photos from toEdit
            this.toEdit.photos = this.toEdit.photos.filter(p => p.id != photoId);

            this.emitChange();
        },

        changePhotoAlbumName(){
            const selected = this.albums.find(a => a.id == this.selectedAlbum);
            const name = selected.name;
            const icon = selected.icon;

            const errorMessage = this.rules.photoAlbumName
                .map(rule => rule(name))
                .find(e => e != true);

            if (!errorMessage){
                // Change
                const newAlbum = this.albums.find(a => a.id == this.selectedAlbum).new;
                if (!newAlbum) this.toEdit.albums.push({id: this.selectedAlbum, name, icon});

                // Set as valid name
                this.validNames.albums.find(e => e.id == this.selectedAlbum).name = name;
                
                this.sortPhotoAlbums();
            } else {
                // Reset to valid name
                const oldName = this.validNames.albums.find(e => e.id == this.selectedAlbum).name;
                this.albums.find(a => a.id == this.selectedAlbum).name = oldName;
            }
        },

        changePhotoAlbumIcon(){
            const selected = this.albums.find(a => a.id == this.selectedAlbum);
            const name = selected.name;
            const icon = selected.icon;

            this.toEdit.albums.push({id: this.selectedAlbum, name, icon});
        },

        emitChange(){
            console.warn('change', this.albums);
            this.$emit("change", this.albums);
        },

        emitError(message, timeout = 3000){
            console.warn('error', {message, timeout});
            this.$emit("error", {message, timeout});
        },

        // Prepare

        prepareAlbumDataForApi(){
            const albums = this.albums
                .map(a => {
                    const id = (a.new) ? null : a.id;
                    const tempId = (a.new) ? a.id : null;
                    const photos = a.photos.map(p => p.file);
                    return {id, tempId, new: a.new, name: a.name, icon: a.icon, photos}
                })

            console.log(JSON.parse(JSON.stringify(albums)))
            return albums;
        },

        albumsValid(){
            return this.albums.every(a => a.photos.length > 0);
        },

        // Save to api
   
        save(){
            return new Promise((resolve, reject) => {

                if (this.albumsValid()){

                    let albums = this.prepareAlbumDataForApi();
        
                    // Delete and edit albums and photos
                    Promise.all([
                        ...this.toDelete.albums.map(a => this.apiDeletePhotoAlbum(a)),
                        ...this.toDelete.photos.map(p => this.apiDeletePhoto(p)),
                        ...this.toEdit.albums.map(a => this.apiEditPhotoAlbum(a.id, a.name, a.icon)),
                        ...this.toEdit.photos.map(p => this.apiEditPhoto(p.id, p.name))
                    ]).then(() => {
        
                        // Add new albums
                        Promise.all(
                            albums
                                .filter(a => a.new)
                                .map(a => this.apiAddPhotoAlbum(a.name, a.icon))
                        )
                        .then((response) => { 
                            // Add autogenerated ids
                            albums = albums.map(a => ({...a, id: (a.new) ? response.find(r => r.name == a.name).id : a.id}))
                
                            // Add new photos
                            Promise.all( 
                                albums
                                    .map(a => ({id: a.id, photos: a.photos.filter(p => p?.lastModified)})) // new photos have lastModified attribute
                                    .filter(a => a.photos.length > 0)
                                    .map(a => this.apiAddPhotos(a.id, a.photos))
                            )
                            .then(() => {
                                console.log('done');
                                resolve(albums);
                            }).catch((err) => {
                                console.error({err});
                                reject();
                            });
                        }).catch((err) => {
                            console.error({err});
                            reject();
                        });
                    }).catch((err) => {
                        console.error({err});
                        reject();
                    });

                } else {
                    this.emitError(this.error.photoAlbumEmpty.message);
                    reject('invalid');
                }
                
            });
        },

        // Api requests
        
        apiAddPhotoAlbum(name, icon){
            return new Promise((resolve, reject) => {
                const ids = this.streamIds.join('-');
                axios
                .post(`${ILLI_API_SERVER_URL}/stream/${ids}/photoAlbum`, {name, icon})
                .then((result) => {
                    console.log({result});
                    resolve(result.data);
                })
                .catch((err) => {
                    console.error({err});
                    reject();
                })
            });
        },

        apiAddPhotos(albumId, photos){
            return new Promise((resolve, reject) => {
                
                let formData = new FormData();
                photos.forEach(photo => {
                    console.log({albumId, photo});
                    formData.append("photos", photo);
                });

                const streamIds = this.streamIds.join('-');

                axios
                .post(`${ILLI_API_SERVER_URL}/stream/${streamIds}/photoAlbum/${albumId}`, 
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((result) => {
                    console.log({result});
                    resolve();
                })
                .catch((err) => {
                    console.error({err});
                    reject();
                })
            });
        },
                
        apiDeletePhotoAlbum(id){
            return new Promise((resolve, reject) => {
                const streamIds = this.streamIds.join('-');
                axios
                .delete(`${ILLI_API_SERVER_URL}/stream/${streamIds}/photoAlbum/${id}`)
                .then((result) => {
                    console.log({result});
                    resolve(result.data);
                })
                .catch((err) => {
                    console.error({err});
                    reject();
                })
            });
        },
                
        apiDeletePhoto(id){
            return new Promise((resolve, reject) => {
                const streamIds = this.streamIds.join('-');
                axios
                .delete(`${ILLI_API_SERVER_URL}/stream/${streamIds}/photoAlbum/photo/${id}`)
                .then((result) => {
                    console.log({result});
                    resolve(result.data);
                })
                .catch((err) => {
                    console.error({err});
                    reject();
                })
            });
        },
                
        apiEditPhotoAlbum(id, name, icon){
            return new Promise((resolve, reject) => {
                const ids = this.streamIds.join('-');
                axios
                .patch(`${ILLI_API_SERVER_URL}/stream/${ids}/photoAlbum/${id}`, {name, icon})
                .then((result) => {
                    console.log({result});
                    resolve(result.data);
                })
                .catch((err) => {
                    console.error({err});
                    reject();
                })
            });
        },
                
        apiEditPhoto(id, name){
            console.log(
                'apiEditPhoto(){',
                {id, name}
            );
            return new Promise((resolve, reject) => {
                const ids = this.streamIds.join('-');
                axios
                .patch(`${ILLI_API_SERVER_URL}/stream/${ids}/photoAlbum/photo/${id}`, {name})
                .then((result) => {
                    console.log({result});
                    resolve(result.data);
                })
                .catch((err) => {
                    console.error({err});
                    reject();
                })
            });
        },

        // Test
        
        test(){
            console.warn(
                JSON.parse(JSON.stringify(
                    this.toDelete
                ))
            );
            console.warn(
                JSON.parse(JSON.stringify(
                    this.toEdit
                ))
            );
            console.warn(
                JSON.parse(JSON.stringify(
                    this.albums
                ))
            );
        }
    }
}
</script>

<style lang="scss">

    .topBar .v-input{
        margin: 0;
        padding: 0;
        display: inline-block;
        position: relative;

        input{
            font-size: 20px;
        }

        .v-input__slot{
            margin: 0;
        }

        .v-text-field__details{
            bottom: -18px;
            position: absolute;
        }
    }


    .vue-file-agent{
        .file-preview-wrapper:not(.file-preview-new) {
            padding-bottom: 28px;

            .file-preview .file-name{
                bottom: -28px;
                top: unset;
                color: #000;
            }

            &:before{
                background: rgb(0 0 0 / 10%);
            }
        }
    } 
    
    .v-pagination{
        padding-top: 20px;
    }

</style>

<style scoped lang="scss">

    $itemsPerRow: 5;
    $gap: 20px;
    $itemWidth: calc((100% / $itemsPerRow) - (($gap * ($itemsPerRow - 1)) / $itemsPerRow));

    .topBar{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .title{
            .breadcrumb{
                display: inline;
                
                .icon{
                    .v-input{
                        max-width: 170px;
                    }
                }
            }

            span{
                padding: 5px 10px;
                border-radius: 5px;
            }

            .clickable{
                cursor: pointer;

                &:hover{
                    background: #f1f1f1;
                }
            }
        }

        .buttons{
            display: flex;
            gap: 10px;
        }
    }

    .albums{
        display: flex;
        flex-wrap: wrap;
        gap: $gap;
        margin: $gap 0 0;

        .album{
            cursor: pointer;
            background: #f1f1f1;
            width: $itemWidth;
            transition: 0.4s;

            .img{
                width: 100%;
                height: 200px;
                overflow: hidden;
                background: #797979;

                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .inner{
                padding: 10px;

                .title{
                    overflow-wrap: break-word;
                }
            }

            &:hover{
                background: #dfdfdf;
            }
        }
    }

    .photos{
        padding-top: $gap;
    }

    @media screen and (max-width: 1000px){
        $itemsPerRow: 4;
        $itemWidth: calc((100% / $itemsPerRow) - (($gap * ($itemsPerRow - 1)) / $itemsPerRow));

        .albums .album{ width: $itemWidth; }
    }

    @media screen and (max-width: 835px){
        $itemsPerRow: 3;
        $itemWidth: calc((100% / $itemsPerRow) - (($gap * ($itemsPerRow - 1)) / $itemsPerRow));

        .albums .album{ width: $itemWidth; }

        .topBar.column{
            flex-direction: column;
            gap: 15px;

            .title{
                display: flex;
                width: 100%;

                .breadcrumb{
                    display: flex;
                    width: 100%;

                    .chevron{
                        text-align: center;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px){
        $itemsPerRow: 2;
        $itemWidth: calc((100% / $itemsPerRow) - (($gap * ($itemsPerRow - 1)) / $itemsPerRow));

        .albums .album{ width: $itemWidth; }

        .topBar.column .title .breadcrumb{

            .chevron{
                flex: 1;    
            }
            .icon{
                display: none;
            }
        } 
    }

</style>