<template>
	<div ref="responsiveContainer" :style="`height:${height}px`">
		<div class="component" data-page="announcement" v-if="vw != 0">
			<div class="inner">
				<span class="icon">
					<i v-if="announcement.icon" :class="`fal fa-${announcement.icon}`"></i>
				</span>
				<span class="title">{{announcement.title}}</span>
				<span class="text">
					<span class="time">van <span class="start">{{announcement.time.event.start}}</span> tot <span class="end">{{announcement.time.event.end}}</span></span>
					<span class="divider">|</span>
					<span class="location" v-if="announcement.location">
						<i class="fal fa-location-dot"></i>
						<span>{{announcement.location}}</span>
					</span>
				</span>
			</div>

			<div class="mini-clock">

				<div class="digital">
					<span class="dayOfWeek">Maandag</span>
					<span class="timeOfDay">Middag</span>
					<span class="clocky">12:00</span>
					<span class="date">1 januari 2023</span>
				</div>
				<div class="analog">
					<div class="clocky">
						<img class="clockFace" src="../assets/images/clock-face.png" alt="clock face">
						<img class="hand bigHand" src="../assets/images/big-hand.png" alt="big hand">
						<img class="hand smallHand" src="../assets/images/small-hand.png" alt="small hand">
					</div>
				</div>

			</div>

			<div class="announcementDescription" v-if="announcement.description">{{announcement.description}}</div>

			<div class="weather">
				<span class="temperature">15°C</span>
				<div class="image">
					<img src="../assets/images/weather/cloudy.png" alt="weather image">
					<span class="description">Bewolkt</span>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		console.log(this.announcement);
		setTimeout(() => {
			this.calcDimensions();
		}, 1000);
	},
	props: {
		announcement: Object,
		height: {
			type: Number,
			default: 800
		}
	},
	watch: {
		height() {
			this.calcDimensions();
		}
	},
	data () {
		return {
			vw: 0,
			vh: 0,
		}
	},
	methods: {
		calcDimensions() {
			this.vw = this.$refs.responsiveContainer.clientWidth / 100;
			this.vh = this.height / 100;
			document.documentElement.style.setProperty('--vw', `${this.vw}px`);
			document.documentElement.style.setProperty('--vh', `${this.vh}px`);
		},
	}
}
</script>

<style scoped>
:root {
  --vw: 0;
  --vwh: 0;
}

*{
  box-sizing: content-box;
  line-height: initial;
}

.component {
	height: 100%;
	position: relative;
	font-family: "Roboto";
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	background: rgb(255, 250, 206);
}

.component[data-page="announcement"].night {
  color: #fff;
}

.component[data-page="announcement"] .mini-clock {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 35;
  width: 100%;
  justify-content: center;
  padding: calc(5 * var(--vh)) 0;
}

.component[data-page="announcement"] .mini-clock .digital {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(20 * var(--vw));
}

.component[data-page="announcement"] .mini-clock .digital .dayOfWeek {
  font-size: calc(2.55 * var(--vw));
  font-weight: bold;
}

.component[data-page="announcement"] .mini-clock .digital .timeOfDay {
  font-size: calc(2.55 * var(--vw));
  font-weight: normal;
  margin-bottom: calc(1.3 * var(--vw));
}

.component[data-page="announcement"] .mini-clock .digital .clocky {
  font-size: calc(4.8 * var(--vw));
  font-weight: bold;
}

.component[data-page="announcement"] .mini-clock .digital .date {
  font-size: calc(1.3 * var(--vw));
  font-weight: normal;
}

.component[data-page="announcement"] .mini-clock .analog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(20 * var(--vw));
}

.component[data-page="announcement"] .mini-clock .analog .clocky {
  position: relative;
}

.component[data-page="announcement"] .mini-clock .analog .clocky .clockFace {
  position: relative;
  width: calc(15 * var(--vw));
  height: calc(15 * var(--vw));
}

.component[data-page="announcement"] .mini-clock .analog .clocky .hand {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(15 * var(--vw));
  height: calc(15 * var(--vw));
}

.component[data-page="announcement"] .inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 55;  
	justify-content: flex-end;
}

.component[data-page="announcement"] .inner .icon {
  font-size: calc(16 * var(--vh));
  margin: calc(3 * var(--vh)) 0;
}

.component[data-page="announcement"] .inner .title {
  font-size: calc(7 * var(--vw))!important;
  font-weight: bold;
  margin: calc(4 * var(--vh)) 0 calc(2 * var(--vh));
  font-family: Arial, Helvetica, sans-serif;
  line-height: inherit;
}

.component[data-page="announcement"] .inner .text {
  font-size: calc(2 * var(--vw));
}

.component[data-page="announcement"].night .inner .text {
  color: #fff;
}

.component[data-page="announcement"] .inner .text .time {
  color: var(--illi-red);
}

.component[data-page="announcement"] .inner .text .divider {
  padding: 0 calc(1 * var(--vw));
}

.component[data-page="announcement"] .inner .text .location {
  color: #393939;
}

.component[data-page="announcement"] .inner .text .location i {
  margin-right: calc(0.3 * var(--vw));
}

.component[data-page="announcement"] .announcementDescription {
  position: absolute;
  left: calc(2 * var(--vw));
  bottom: calc(2 * var(--vw));
  background-color: #fff;
  padding: calc(1 * var(--vw));
  font-size: calc(1.5 * var(--vw));
  width: calc(20 * var(--vw));
  border-radius: calc(1 * var(--vw));
}

.component[data-page="announcement"] .weather {
  display: flex;
  flex-direction: column;
  width: calc(13 * var(--vw));
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: calc(2 * var(--vw));
  bottom: calc(2 * var(--vw));
}

.component[data-page="announcement"] .weather .temperature {
  font-size: calc(1.22 * var(--vw));
  font-weight: bold;
  width: 100%;
  margin-bottom: calc(-1 * var(--vw));
}

.component[data-page="announcement"] .weather .image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.component[data-page="announcement"] .weather .image img {
  width: calc(6 * var(--vw));
}

.component[data-page="announcement"] .weather .image .description {
  font-size: calc(1.22 * var(--vw));
  font-weight: normal;
}
</style>