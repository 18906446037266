<template>
    <div>
        <div class="calendar" :class="responsiveClass" :style="responsiveClass != 'stack' ? `height:${height}px` : ''" ref="calendar">
            <div class="overview" :class="hideUpcoming ? 'hideUpcoming' : ''">
                <h4 class="flexHeader">
                    <span class="overviewHeader">{{$t('linkDevice.announcements.overview')}}</span>
                    <v-btn class="overviewToggle" color="black" dark small @click="toggleOverview">{{$t('linkDevice.announcements.overview')}} {{overviewToggleText}}</v-btn>
                    <v-btn color="primary" small @click="clickNewAnnouncement">{{$t('linkDevice.announcements.add')}}</v-btn>
                </h4>

                <div class="upcomingDays" ref="upcomingDays" @scroll="checkScrollBottom">
                    <div class="day" v-for="day in upcomingDays" :key="day.date">
                        <div class="label">{{day.date}}</div>
                        <div class="announcements">
                            <div class="announcement" v-for="(item, index) in day.items" :key="index" @click="openAnnouncement(item.id)" :class="item.selected ? 'selected' : ''">
                                <div class="content">
                                    <div class="time">{{item.time.announcement.start}} - {{item.time.announcement.end}}</div>
                                    <div class="announcementTitle">{{item.title}}</div>
                                </div>
                                <v-icon>mdi-chevron-right</v-icon>
                            </div>
                        </div>
                    </div>
                    <div v-if="upcomingDays.length == 0">{{$t('linkDevice.announcements.noUpcomingDays')}}</div>
                    <div class="loadAnimation" v-if="this.announcements.find(a => !['null', null].includes(a.repeat_every)) !== undefined">
                        <i class="fa-light fa-spinner-third fa-spin"></i>
                    </div>
                </div>
            </div>
            <div class="form" ref="form">
                <v-text-field v-model="form.values.title" :rules="form.rules.title" :label="$t('linkDevice.announcements.title')" counter clearable></v-text-field>
                <div class="formRow">
                    <date-picker v-model="form.values.date" format="DD-MM-YYYY" type="date" value-type="format" :placeholder="$t('linkDevice.announcements.date')" @change="changeDate"/>
                    <v-select v-model="form.values.repeat" :label="$t('linkDevice.announcements.repeat')" :items="form.options.repeat" item-text="label" item-value="value" class="w-100"></v-select>
                </div>
                <div class="formRow formRowResponsive">
                    <div class="formCol">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span class="formLabel" v-on="on">Aankondiging <v-icon>mdi-information-outline</v-icon></span>
                            </template>
                            <span>Wanneer het agendapunt weergegeven wordt</span>
                        </v-tooltip>

                        <div class="formRow">
                            <Time v-model="form.values.time.announcement.start" :noMargins="true" :placeholder="$t('linkDevice.announcements.time.start')" class="w-100"/>
                            <div class="until">{{$t('linkDevice.announcements.time.until')}}</div>
                            <Time v-model="form.values.time.announcement.end" :noMargins="true" :placeholder="$t('linkDevice.announcements.time.end')" class="w-100"/>
                        </div>
                    </div>
                    <div class="formCol">                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span class="formLabel" v-on="on">Evenement <v-icon>mdi-information-outline</v-icon></span>
                            </template>
                            <span>Welke tijd op het agendapunt weergegeven wordt</span>
                        </v-tooltip>

                        <div class="formRow">
                            <Time v-model="form.values.time.event.start" :noMargins="true" :placeholder="$t('linkDevice.announcements.time.start')" class="w-100"/>
                            <div class="until">{{$t('linkDevice.announcements.time.until')}}</div>
                            <Time v-model="form.values.time.event.end" :noMargins="true" :placeholder="$t('linkDevice.announcements.time.end')" class="w-100"/>
                        </div>
                    </div>
                </div>
                <v-text-field v-model="form.values.location" :rules="form.rules.location" :label="$t('linkDevice.announcements.location')" counter clearable></v-text-field>
                <v-textarea v-model="form.values.description" :rules="form.rules.description" :label="$t('linkDevice.announcements.description')" counter clearable></v-textarea>

                <IconSelect v-model="form.values.icon"></IconSelect>

                <div class="actions">
                    <v-btn color="light" small @click="modal.preview.display = true">{{$t('linkDevice.announcements.preview')}}</v-btn>
                    <v-btn color="red" dark small @click="deleteAnnouncement">{{$t('linkDevice.announcements.delete')}}</v-btn>
                    <v-btn color="primary" small @click="saveAnnouncement">
                        {{ (announcementSelected) ? $t('linkDevice.announcements.save.edit') : $t('linkDevice.announcements.save.add') }}
                    </v-btn>
                </div>

                <div v-if="form.errorMessage" class="alert alert-danger">{{form.errorMessage}}</div>
            </div>
        </div>
        
        <v-dialog v-model="modal.preview.display" :width="modal.preview.maxWidth">
            <v-card>
                <v-card-title>
                    {{ $t("linkDevice.announcements.modal.preview.title") }}
                </v-card-title>

                <Announcement :announcement="form.values" :height="modal.preview.height"></Announcement>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="modal.preview.display = false">
                        {{ $t("linkDevice.announcements.modal.preview.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import DatePicker from 'vue2-datepicker';   
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import iconsJson from '../assets/fontawesome/icons.json';
import Announcement from '@/components/Announcement.vue';
import IconSelect from '@/components/IconSelect.vue';
import axios from "axios";
import { ILLI_API_SERVER_URL } from "../config";
import Time from '@/components/Time.vue';

export default {
    components: {
        DatePicker,
        Announcement,
        IconSelect,
        Time
    }, 
    props: {
        streamIds: {
            type: Array,
            default: () => [],
        },
        height: {
            type: Number,
            default: 612
        }
    },
    computed: {
        announcementSelected(){
            return this.upcomingDays.some((day) => day.items.some((item) => item.selected));
        }
    },
    created() {

        window.addEventListener('resize', this.handleResize);

        this.changeDate(
            DateTime.now().toFormat('dd-MM-yyyy')
        );

        this.form.options.icons = Object.entries(iconsJson)
            // eslint-disable-next-line no-unused-vars
            .filter(([_, value]) => value.styles.includes('light'))
            .map(([key, value]) => ({key: key, label: value.label, terms: value.search.terms, search: `${value.label} ${value.search.terms.join(' ')}`}));
        
        Promise.all(
            this.streamIds.map(id => this.loadAnnouncements(id))
        ).then((allAnnouncements) => {
            
            this.announcements = this.getCommonAnnouncements(...allAnnouncements);

            this.initUpcomingDays();
            this.newAnnouncement();
            this.generateValidationRules();
            this.handleResize();

            this.overviewToggleText = this.$t('linkDevice.announcements.overviewToggle.hide');

        })
       
    },
    data: () => ({

        responsiveClass: '',
        hideUpcoming: false,
        overviewToggleText: '',

        repeatEveryFormat: new Map([
            ["week", "c"],
            ["month", "d"],
            ["year", "ddMM"],
        ]),

        checkDay: null,

        upcomingDays: [],

        announcements: [],

        changes: {
            post: new Set(),
            patch: new Set(),
            delete: new Set()
        },

        form: {
            options: {
                icons: [],
                repeat: []
            },
            values: {
                new: null,
                id: null,
                title: null,
                date: null,
                time: {
                    start: null,
                    end: null
                },
                repeat: null,
                location: null,
                description: null,
                icon: null,
            },
            rules: {},
            errorMessage: null,
        },

        modal: {
            preview: {
                display: false,
                maxWidth: 900,
                height: 529,
            }
        }

    }),
    methods: {

        loadAnnouncements(id){
            return new Promise((resolve, reject) => {
                axios
                .get(`${ILLI_API_SERVER_URL}/announcementsStream-old/${id}`)
                .then((results) => {
    
                    const announcements = results.data
                        .filter(e => e.categories.includes('personal'))
                        .map((announcement) => {
                            return {
                                ...announcement,
                                dates: announcement.dates.map((date) => {return DateTime.fromFormat(date, "yyyy-LL-dd").toFormat("dd-LL-yyyy")}),
                                event_start: (announcement.event_start) ? DateTime.fromFormat(announcement.event_start, "HH:mm:ss").toFormat("HH:mm") : null,
                                event_end: (announcement.event_end) ? DateTime.fromFormat(announcement.event_end, "HH:mm:ss").toFormat("HH:mm") : null,
                                announcement_start: (announcement.announcement_start) ? DateTime.fromFormat(announcement.announcement_start, "HH:mm:ss").toFormat("HH:mm") : null,
                                announcement_end: (announcement.announcement_end) ? DateTime.fromFormat(announcement.announcement_end, "HH:mm:ss").toFormat("HH:mm") : null,
                                new: false,
                            }
                        })
    
                    console.log('announcements', id, announcements);
    
                    resolve(announcements);
                }).catch((err) => {
                    console.error({err});
                    reject([]);
                })
            });
        },

        initUpcomingDays(){
            this.upcomingDays = [];
            this.checkDay = DateTime.now();
            this.loadUpcomingAnnouncements(10)
        },

        hasUpcomingEvent(day){
            
            const hasUpcomingEvent = this.announcements.find((a) => {
                const repeating = ['day', 'week', 'month', 'monthWeek', 'year'].includes(a.repeat_every);
                if (repeating) return true;

                const hasUpcomingDate = a.dates.find((d) => {
                    const announcementDate = DateTime.fromFormat(d, "dd-MM-yyyy");                    
                    const difference = Math.floor(day.diff(announcementDate).as('days'));
                    return (difference <= 0);
                }) !== undefined;

                return hasUpcomingDate;
            }) !== undefined;

            return hasUpcomingEvent;

        },

        loadUpcomingAnnouncements(minimalAmountOfItems = 5){

            let itemsFound = [];
            let hasUpcomingEvent = this.hasUpcomingEvent(this.checkDay);

            while (itemsFound.length < minimalAmountOfItems && hasUpcomingEvent) { // TODO: stop when no items with repeat...
                
                itemsFound.push(
                    ...this.getAnnouncementsOnDay(this.checkDay)
                );

                this.checkDay = this.checkDay.plus({day: 1});
                hasUpcomingEvent = this.hasUpcomingEvent(this.checkDay);
            }

            const itemsPerDay = itemsFound.reduce((all, crr) => {
                const item = { id: crr.id, title: crr.title, time: { event: { start: crr.event_start, end: crr.event_end }, announcement:{ start: crr.announcement_start, end: crr.announcement_end }  }, selected: crr.id == this.form.values.id };
                const itemsWithDate = all.find(e => e.date === crr.dayString);

                if (itemsWithDate) {
                    itemsWithDate.items.push(item);
                } else {
                    all.push({ date: crr.dayString, items: [item] });
                }

                return all; 
            }, []);

            this.upcomingDays.push(...itemsPerDay)
            
        },

        getAnnouncementsOnDay(day){ // TODO: take starting date in consideration 

            const dayStr = day.setLocale('nl').toFormat('ccc. d MMM yy');

            // console.log('checking', dayStr);
            let itemsOnDay = [];

            for (const announcement of this.announcements) {
                const isOnDay = this.announcementIsOnDay(announcement, day);
                if (isOnDay) itemsOnDay.push({...announcement, dayString: dayStr})
                // console.log(announcement.title, {isOnDay}, announcement.dates);
            }

            itemsOnDay.sort((a, b) => parseInt(a.announcement_start?.replace(':', '') || 0) - parseInt(b.announcement_start?.replace(':', '')) || 0)

            return itemsOnDay;
        },

        announcementIsOnDay(announcement, day) {
            const hasStarted = announcement.dates.filter((d) => {
                const announcementDate = DateTime.fromFormat(d, "dd-MM-yyyy");                    
                const difference = Math.floor(day.diff(announcementDate).as('days'));
                return (difference >= 0);
            });
    
            if (hasStarted.length == 0) return false;    
            if (announcement.repeat_every == "day") return true;

            if (announcement.repeat_every == "monthWeek"){
                const target = this.getDayOfWeekAndWeekOfMonth(day);

                const isOnMonthWeek = announcement.dates.find((d) => {
                    const announcementDate = DateTime.fromFormat(d, "dd-MM-yyyy");
                    const tested = this.getDayOfWeekAndWeekOfMonth(announcementDate);
                    return (target.dayOfWeek == tested.dayOfWeek && target.weekOfMonth == tested.weekOfMonth);
                }); 

                return (isOnMonthWeek !== undefined);
            }
           
            const format = (announcement.repeat_every != null && announcement.repeat_every != 'null') ? this.repeatEveryFormat.get(announcement.repeat_every) : 'ddMMyyyy';
            const datesFormatted = announcement.dates.map(d => DateTime.fromFormat(d, "dd-MM-yyyy").toFormat(format)); 
            const dayFormatted = day.toFormat(format);

            if (datesFormatted.includes(dayFormatted)) return true;

            return false;
        },

        getDayOfWeekAndWeekOfMonth(date){
            const dayOfWeek = date.toLocaleString({ weekday: 'long' });
            const weekOfMonth = Math.ceil(date.day / 7);
            return {dayOfWeek, weekOfMonth};
        },

        checkScrollBottom() {
            const scrollContainer = this.$refs.upcomingDays;

            if (scrollContainer.scrollTop + scrollContainer.clientHeight > (scrollContainer.scrollHeight - 20)) {
                this.loadUpcomingAnnouncements();
            }
        },

        openAnnouncement(id){
            const announcement = this.announcements.find(e => e.id == id);
            console.warn('OPEN', announcement);

            this.form.errorMessage = null;

            const date = announcement.dates[0]; // ?

            this.form.values = {
                new: false,
                id: announcement.id,
                title: announcement.title,
                date: date, // ?
                time: {
                    announcement: {
                        start: announcement.announcement_start,
                        end: announcement.announcement_end
                    },
                    event: {
                        start: announcement.event_start,
                        end: announcement.event_end
                    }
                },
                repeat: announcement.repeat_every,
                location: announcement.location,
                description: announcement.text,
                icon: announcement.icon,
            }

            // add selected class
            this.upcomingDays = this.upcomingDays.map(day => ({...day, items: day.items.map(item => ({...item, selected: item.id == id})) }) )

            this.changeDate(date);
        },

        clickNewAnnouncement(){
            this.newAnnouncement();

            const targetElement = this.$refs.form;
            targetElement.scrollIntoView({behavior: 'smooth'});
        },

        newAnnouncement(){
            this.form.values = {
                new: true,
                id: null,
                title: null,
                date: DateTime.now().toFormat('dd-MM-yyyy'),
                time: {
                    announcement: {
                        start: null,
                        end: null
                    },
                    event: {
                        start: null,
                        end: null
                    }
                },
                repeat: 'null',
                location: null,
                description: null,
                icon: 'thumbtack',
            }
            // remove selected class
            this.upcomingDays = this.upcomingDays.map(day => ({...day, items: day.items.map(item => ({...item, selected: false})) }) )
        },

        saveAnnouncement(){

            const validation = this.validateForm(); 

            if (validation.valid){

                this.form.errorMessage = null;

                const announcement = this.form.values;
                const isNew = (this.announcements.find(a => a.id == announcement.id) === undefined);
    
                console.log('SAVE', isNew);
    
                if (isNew){
    
                    const id = this.getTemporaryId()
    
                    this.announcements.push({
                        new: true,
                        id: id,
                        title: announcement.title,
                        text: announcement.description,
                        event_start: announcement.time.event.start,
                        event_end: announcement.time.event.end,
                        announcement_start: announcement.time.announcement.start,
                        announcement_end: announcement.time.announcement.end,
                        repeat_every: announcement.repeat,
                        announce_days_before: 0,
                        location: announcement.location,
                        category: 4,
                        dates: [announcement.date],
                        icon: announcement.icon
                    })
    
                    this.changes.post.add(id);
    
                    this.form.values.id = id;
    
                } else {
    
                    this.announcements = this.announcements
                        .map((a) => {
                            return (a.id == announcement.id) 
                                ? {
                                    ...a,
                                    title: announcement.title,
                                    text: announcement.description,
                                    event_start: announcement.time.event.start,
                                    event_end: announcement.time.event.end,
                                    announcement_start: announcement.time.announcement.start,
                                    announcement_end: announcement.time.announcement.end,
                                    repeat_every: announcement.repeat,
                                    location: announcement.location,
                                    dates: [announcement.date],
                                    icon: announcement.icon
                                }
                                : a
                        });
    
                    if (!announcement.new) this.changes.patch.add(announcement.id);
    
                }            
    
                this.initUpcomingDays();
                this.emitChanges();

            } else {

                this.form.errorMessage = validation.message;

            }

        },

        deleteAnnouncement(){

            console.log('DELETE');

            this.announcements = this.announcements.filter(a => a.id != this.form.values.id);

            this.changes.post?.delete(this.form.values.id);
            this.changes.patch?.delete(this.form.values.id);
            
            if (!this.form.values.new) this.changes.delete.add(this.form.values.id);

            this.newAnnouncement();

            this.initUpcomingDays();
            this.emitChanges();

        },

        changeDate(date){           
            const dateTime = DateTime.fromFormat(date, "dd-MM-yyyy").setLocale('nl');
            const weekOfMonth = this.getDayOfWeekAndWeekOfMonth(dateTime).weekOfMonth;

            const dayInWeek = dateTime.toFormat('cccc');
            const dayInMonth = dateTime.toFormat('d');
            const dayAndMonth = dateTime.toFormat('d MMMM');

            const suffix = 'e';

            this.form.options.repeat = [
                {label: `${this.$t('linkDevice.announcements.repeatOptions.once')}`, value: 'null'},
                {label: `${this.$t('linkDevice.announcements.repeatOptions.daily')}`, value: 'day'},
                {label: `${this.$t('linkDevice.announcements.repeatOptions.weekly')} ${dayInWeek}`, value: 'week'},
                {label: `${this.$t('linkDevice.announcements.repeatOptions.monthly')} ${dayInMonth}${suffix}`, value: 'month'},
                {label: `${this.$t('linkDevice.announcements.repeatOptions.monthlyWeek')} ${weekOfMonth}${suffix} ${dayInWeek}`, value: 'monthWeek'},
                {label: `${this.$t('linkDevice.announcements.repeatOptions.yearly')} ${dayAndMonth}`, value: 'year'},
            ]
        },

        getTemporaryId(){ // Used to (temporarly) identify items
            return Date.now();
        },

        getCommonAnnouncements(...arrays) {
            if (arrays.length == 1) {
                return arrays[0];
            }

            // Use the first array as a reference and filter items that occur in all arrays
            const commonAnnouncements = arrays[0].filter(announcement => arrays.every(array => array.some(a => a.id == announcement.id)));
            return commonAnnouncements;
        },

        validateForm(){
            const form = this.form.values;
            const required = [form.title, form.date, form.time.event.start, form.time.event.end, form.time.announcement.start, form.time.announcement.end];
            const requiredValid = required.filter(e => e).length == required.length;
            const timeValid = (requiredValid) 
                ? (
                    parseInt(form.time.event.start.replace(':', '')) < parseInt(form.time.event.end.replace(':', ''))
                    && 
                    parseInt(form.time.announcement.start.replace(':', '')) < parseInt(form.time.announcement.end.replace(':', '')) 
                ) : false;
            const charLimitValid = Object.entries(this.form.rules).every(([key, rule]) => {
                const value = this.form.values[key];
                const valid = (rule[0](value) == true);
                return valid;
            });

            const message = (requiredValid) 
                ? (timeValid)
                    ? (charLimitValid)
                        ? this.$t('linkDevice.announcements.validation.success')
                        : this.$t('linkDevice.announcements.validation.error.charLimit')
                    : this.$t('linkDevice.announcements.validation.error.time')
                : this.$t('linkDevice.announcements.validation.error.required');

            const valid = (requiredValid && timeValid && charLimitValid)
            
            console.log({valid, message});
            return {valid, message};
        },

        getChanges(){
            return Object.fromEntries(
                Object.entries(this.changes).map(([type, idsSet]) => {
                    if (type == 'delete') return [type, [...idsSet]];
                    const announcements = Array.from(idsSet).map((id) => {
                        const announcement = this.announcements.find(a => a.id == id); 
                        return { // make data ready for api
                            ...announcement,
                            id: (announcement.new) ? null : announcement.id,
                            new: undefined,
                            categories: undefined,
                            category: [4], // TODO
                            announce_days_before: announcement.announce_days_before ?? 0,
                            stream_locations: undefined,
                            streams: this.streamIds,
                            event_start: `${announcement.event_start}:00`,
                            event_end: `${announcement.event_end}:00`,
                            announcement_start: `${announcement.announcement_start}:00`,
                            announcement_end: `${announcement.announcement_end}:00`,
                            dates: announcement.dates.map(d => DateTime.fromFormat(d, 'dd-MM-yyyy').toFormat('yyyy-MM-dd')),
                            text: announcement.text ?? '',
                            location: announcement.location ?? '',
                            icon: announcement.icon ?? '',
                        }
                    }).filter(e => e)
                    return [type, announcements];
                })
            );
        },

        emitChanges(){

            console.log('emit');

            const changes = this.getChanges();

            console.log('changes');
            console.log(changes);
            this.$emit("change", {changes});
        },

        getMaxMessage(characterLimit){
            return `${this.$t('linkDevice.announcements.validation.rule.max')} ${characterLimit} ${this.$t('linkDevice.announcements.validation.rule.chars')}`
        },

        generateValidationRules(){
            this.form.rules = {
                title: [v => (v?.length <= 25 || v == null) || this.getMaxMessage(25)],
                description: [v => (v?.length <= 150 || v == null) || this.getMaxMessage(150)],
                location: [v => (v?.length <= 25 || v == null) || this.getMaxMessage(25)],
            }
        },

        handleResize() {
            this.$nextTick(() => {
                const width = this.$refs.calendar.clientWidth;

                this.responsiveClass = (width < 1150) 
                    ? (width < 920)
                        ? 'stack' 
                        : 'smallForm'
                    : '';

                const previewWidth = Math.min((window.innerWidth - 48), this.modal.preview.maxWidth);
                this.modal.preview.height = previewWidth / 1.77;
            })
        },

        toggleOverview(){
            this.hideUpcoming = !this.hideUpcoming;
            this.overviewToggleText = (this.hideUpcoming)
                ? this.$t('linkDevice.announcements.overviewToggle.show')
                : this.$t('linkDevice.announcements.overviewToggle.hide')

        },

    }
}
</script>

<style lang="scss">

    .calendar .overview .upcomingDays .day .announcements .announcement{

        .v-icon{
            color: transparent;
        }

        &:hover{
            .v-icon{
                color: rgba(0, 0, 0, 0.5);
            }
        }

        &.selected{
            .v-icon{
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }

    .calendar .form .v-label.theme--light:not(.primary--text){
        color: #757575 !important;
    }

</style>

<style lang="scss" scoped>
    .calendar{
        display: flex;
        gap: 20px;

        .overview{
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            
            .flexHeader{
                display: flex;
                justify-content: space-between;
                margin: 16px 0;

                .overviewHeader{
                    display: block;
                }
                .overviewToggle{
                    display: none;
                }
            }

            .upcomingDays{
                display: flex;
                flex-direction: column;
                gap: 20px;
                overflow-y: auto;

                .day{
                    .label{
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                }

                .announcements{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .announcement{
                        background: #f1f1f1;
                        border-bottom: 1px solid grey;
                        display: flex;
                        gap: 15px;
                        padding: 5px 15px;
                        cursor: pointer;
                        justify-content: space-between;

                        .content{
                            display: flex;
                            align-items: center;
                            
                            .time{
                                color: grey;
                                margin-right: 20px;
                            }
                            .announcementTitle{
                                font-weight: 500;
                            }
                        }

                        &.selected{
                            background: #dceeff;
                            border-color: #0e0e0e;
                        }
                    }
                }

                .loadAnimation{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 200px;
                    font-size: 30px;
                }
            }

            &:after{
                content: '';
                background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 50px;
            }
        }

        .form{
            flex: 2;

            .formRow{
                display: flex;
                gap: 20px;
                align-items: center;
                margin-bottom: 22px;

                .formCol{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    width: 100%;

                    .formLabel{
                        font-size: 12px;
                        height: 20px;
                        line-height: 20px;
                        color: #757575;

                        .v-icon{
                            font-size: inherit;
                        }
                    }
                }
            }

            .actions{
                display: flex;
                justify-content: flex-end;
                gap: 15px;
            }
        }

        &.smallForm{
            .form{
                flex: 1;
            }
        }

        // responsive
        &.stack{
            flex-direction: column;

            .overview{
                flex: unset;
                height: 50vh;

                .flexHeader{
                    .overviewHeader{
                        display: none;
                    }
                    .overviewToggle{
                        display: block;
                    }
                }

                &.hideUpcoming{
                    height: auto;

                    .upcomingDays{
                        display: none;
                    }
                    &:after{
                        display: none;
                    }
                }
            }

            .form{
                .formRow{
                    .until{
                        display: none;
                    }

                    &.formRowResponsive{
                        flex-direction: column;
                    }
                }

                .actions{
                    flex-direction: column;
                }
            }
        }
    }

    .customRow{
        .icon{
            border: 1px solid rgb(199, 199, 199);
            padding: 5px;
            display: inline-block;
            margin-right: 10px;
        }

        &.selection{
            .icon{
                margin: 2px;
                margin-right: 10px;
            }
        }
    }
</style>
